import ClassCard from "app/components/ClassCard";
import { Slider } from "app/components/Slider";
import React from "react";
import { HomeSelectContentContainer } from "services/typewriter/segment";
import { ClassCardSkeleton } from "app/components/ClassCard/ClassCardSkeleton";
import { Section } from "../Section";
import { useGetFeaturedClasses } from "./hooks";

export const FeaturedClasses = () => {
  const { loading, featuredClasses } = useGetFeaturedClasses();
  const loadingStateComponent = () => {
    return (
      <Slider
        data={[{}, {}, {}, {}, {}]}
        slideComponent={ClassCardSkeleton}
        arrowTopPosition="35%"
        slidesSpacing={12}
        cardHeight="186px"
      />
    );
  };

  return (
    <Section
      title="Featured Classes"
      showLoadingState={loading}
      showTitleLoadingState
      loadingStateComponent={loadingStateComponent()}
    >
      <Slider
        data={featuredClasses}
        slideComponent={(props: any) => (
          <ClassCard
            {...props}
            container={HomeSelectContentContainer.FeaturedContent}
            selectedFrom="FeaturedClasses"
          />
        )}
        slidesSpacing={24}
        cardHeight="190px"
      />
    </Section>
  );
};
