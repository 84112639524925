import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { H2 } from "app/components/Typography";
import styled from "styled-components";
import themeSpace from "theme/space";
import { Link } from "react-router-dom";

export const CenterWrapper = styled(Flex).attrs({
  justifyContent: "center",
  mt: 32,
})``;

export const Container = styled(Div).attrs({
  px: [0, 0, 3, 4],
  width: "100%",
})``;

export const Title = styled(H2).attrs({
  color: "black",
})`
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: 0.01em;
  text-transform: capitalize;
`;

export const ContentWrapper = styled(Flex).attrs({})``;

export const HeaderLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blue};

  :hover {
    opacity: 0.8;
    text-decoration: none;
  }
`;

export const Header = styled(Flex).attrs(() => ({
  flexDirection: ["column", "column", "row"],
  alignItems: ["start", "start", "center"],
  mb: "12px",
  pl: [3, 3, 0],
}))`
  ${Title} {
    padding: 0;
    padding-bottom: ${themeSpace[1]}px;
    margin-right: ${({ theme }) => theme.space[2]}px;
  }
`;
