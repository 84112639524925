import { SkeletonText, SkeletonTextLoader } from "app/components/SkeletonText";
import React from "react";
import {
  CenterWrapper,
  Container,
  ContentWrapper,
  Header,
  Title,
  HeaderLink,
} from "./styles";

type SectionProps = {
  children: React.ReactNode;
  title?: string;
  loadingStateComponent?: React.ReactNode;
  showLoadingState?: boolean;
  showTitleLoadingState?: boolean;
  emptyStateComponent?: React.ReactNode;
  showEmptyState?: boolean;
  headerLink?: HeaderLink;
};

interface HeaderLink {
  text: string;
  route: string;
}

export const Section = ({
  children,
  title,
  loadingStateComponent,
  showLoadingState,
  showTitleLoadingState,
  emptyStateComponent,
  showEmptyState,
  headerLink,
}: SectionProps) => {
  return (
    <CenterWrapper>
      <Container>
        {showLoadingState && showTitleLoadingState && (
          <SkeletonText height="24px" width="202px" mb="12px">
            <SkeletonTextLoader width="100%" height="100%" />
          </SkeletonText>
        )}
        {!showLoadingState && title && (
          <Header>
            <Title>{title}</Title>
            {headerLink && (
              <HeaderLink to={headerLink.route}>{headerLink.text}</HeaderLink>
            )}
          </Header>
        )}
        <ContentWrapper>
          {showLoadingState && loadingStateComponent}
          {!showLoadingState && showEmptyState && emptyStateComponent}
          {!showLoadingState && !showEmptyState && children}
        </ContentWrapper>
      </Container>
    </CenterWrapper>
  );
};
