import { Class, useGetFeaturedContentQuery } from "services/graphql";

export function useGetFeaturedClasses() {
  const { data, loading, error } = useGetFeaturedContentQuery();
  const isLoading = loading || !data?.featuredClasses;
  const featuredClasses: Class[] = data?.featuredClasses?.map(
    (edge: any) => edge?.content
  );

  return {
    featuredClasses,
    loading: isLoading,
    error,
  };
}
